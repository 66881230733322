import Layout from '@core/Layout'
import '../../ui-widgets/searchSplittedAutocomplete.widget'

export default class SearchWithSplittedResult extends Layout {
  constructor($form, options) {
    super()

    this.options = {
      appendTo: '',
      searchInput: '',
      searchUrl: '',
      method: 'POST',
      queryParams: {},
      source: null,
      getItem: (item, newText, hasInProgressItem) => {
        // Custom method as template for item
        //hasInProgressItem - when we show status
        //just an example for item
        return `
          <a href="#">${newText}</a>
        `
      },
      ...options,
    }
    this.$form = $form
    this.$searchInput = this.$form.find(this.options.searchInput)
    this.searchUrl = this.options.searchUrl

    this.getItem = this.options.getItem

    this.init()
  }

  init() {
    const self = this

    this.$searchInput.splittedAutocomplete({
      appendTo: this.options.appendTo,
      source: (req, res) => {
        $.ajax({
          method: self.options.method,
          url: Routing.generate(this.searchUrl, { search: req.term, ...this.options.queryParams }),
        }).done((data) => {
          if (typeof this.options.source === 'function') {
            this.options.source(res, data)
            return
          }

          const customers = data.customers?.map((item) => {
            const ac = {}

            //autocomplete default values REQUIRED
            ac.value = item.title
            ac.type = item.type
            ac.item = item

            return ac
          })

          const projects = data.projects?.map((item) => {
            const ac = {}

            //autocomplete default values REQUIRED
            ac.value = item.title
            ac.type = item.type
            ac.item = item

            return ac
          })

          const tasks = data.tasks?.map((item) => {
            const ac = {}

            //autocomplete default values REQUIRED
            ac.value = item.title
            ac.type = item.type
            ac.item = item

            return ac
          })

          res([...customers, ...projects, ...tasks])
        })
      },
      delay: 500,
      open: function (event, ui) {},
      focus: function (event, ui) {
        event.preventDefault()
      },
      getItem: this.getItem,
    })
  }
}
