import SearchWithSplittedResult from '@components/SearchWithSplittedResult'

export const GlobalSearch = () => {
  const $dashboardSearch = $(document).find('#global-search')
  const $dashboardSearchInput = $dashboardSearch.find('input#global-search-input')

  if ($dashboardSearchInput.length) {
    new SearchWithSplittedResult($dashboardSearch, {
      appendTo: '#global-search .search-box',
      searchInput: '#global-search-input',
      searchUrl: 'app_ajax_global_search',
      getItem: ({ item }, text) => {
        const urlByType = {
          task: Routing.generate('app_task_show', { teamId, id: item.id }),
          project: Routing.generate('app_project_tasks', { id: item.id }),
          customer: Routing.generate('app_customer_edit', { teamId, id: item.id }),
        }

        let avatarCircle = `<img src="${item.avatarSrc}" class="img-circle user-avatar" />`

        if (!item.avatarSrc || item.avatarSrc === '') {
          if (item.type === 'task') {
            avatarCircle = `${item.firstName[0]?.toUpperCase()}${item.lastName[0]?.toUpperCase()}`
          } else {
            avatarCircle = `${item.title[0].toUpperCase()}`
          }
        }

        return `
            <a href="${urlByType[item.type]}" class="ui-menu-item-wrapper">
              <span class="box-radius">
                <span class="user-avatar-container">
                  ${avatarCircle}
                </span>
              </span>
              <span class="found-element-text">${text}</span>
            </a>
          `
      },
    })

    $dashboardSearchInput.on('keydown keyup', (e) => {
      const $this = $(e.currentTarget)

      $this.closest('.search').toggleClass('is-empty', e.currentTarget.value === '')
    })

    $dashboardSearch.find('.search-input-placeholder').on('click', () => $dashboardSearchInput.focus())
  }
}
