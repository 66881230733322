import { useHttp } from '@utils/useHttp'
import Layout from '@core/Layout'

export default class OnlineUsers extends Layout {
  constructor() {
    super({
      selector: '.online-users-wrapper',
    })

    this.$placeholder = this.$target.find('.online-users-placeholder')
    this.data = {}

    this.init()
  }

  async init() {
    await this.fetchData()
    this.render()
    setInterval(async () => {
      await this.fetchData()
      this.render()
    }, 10000)
  }

  async fetchData() {
    const { request } = useHttp()

    try {
      const data = await request(Routing.generate('app_user_online_users'), 'GET')
      this.data = data.onlineUsers
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  render() {
    this.reset()

    Object.keys(this.data).length &&
      Object.keys(this.data).forEach((key) => this.$placeholder.append(this.getUserRowHtml(this.data[key])))
  }

  getUserRowHtml({ avatarSrc, nickname, startedTask }) {
    let taskLink = ''

    if (startedTask) {
      taskLink = `<a href="${Routing.generate('app_task_show', {
        teamId: startedTask.teamId,
        id: startedTask.taskId,
      })}" class="bottom-row">${startedTask.taskAlias} / ${startedTask.project}</a>`
    }

    return `
      <div class="employee-outer-box">
        <div class="image-box box-radius med">
          <span class="user-online"></span>
          <div class="user-avatar-container">
            <img src="${
              avatarSrc ? avatarSrc : '/build/img/default_avatar_male.jpg'
            }" width="40" height="40" class="img-circle user-avatar" alt="${nickname}" />
          </div>
        </div>
        <div class="about-task-box">
            <div class="top-row">@${nickname}</div>
            ${startedTask ? taskLink : ''}
        </div>
      </div>
    `
  }

  reset() {
    this.$placeholder.empty()
  }
}
