/** GLOBAL FUNCTIONS FOR ALL PAGES **/
import IsMobile from '@utils/IsMobile'
import { localeDatepicker } from '@utils/localeDatepicker'
import OnlineUsers from '@components/OnlineUsers'
import TaskStopPopup from '@components/TaskStopPopup'
import { GlobalSearch } from '@components/GlobalSearch'
const $document = $(document)

$document.ready(function () {
  /* Hack to use _locale on all generated request - DO NOT REMOVE */
  // change name of initial method
  Routing.generateImpl = Routing.generate
  // override generate fonction by adding a default _locale from request locale
  Routing.generate = function (url, params) {
    var paramsExt = {}
    if (params) {
      paramsExt = params
    }
    if (!paramsExt._locale) {
      paramsExt._locale = Translator.locale
    }
    return Routing.generateImpl(url, paramsExt)
  }

  isMobile && new IsMobile()

  //load timer
  if (typeof timerTimeStr !== 'undefined') {
    const TEdTimer = new TimeEdTimer({
      timeStr: timerTimeStr,
      setTotalTimeSelector: '.task-spend-time',
    })

    TEdTimer.run()
    $(document).trigger('timer-start')
  } else {
    $(document).trigger('timer-stop')
  }

  const $sidebar = $('aside.sidebar')
  const $mobilePageOverlay = $document.find('.mobile-page-overlay')
  const fixedActionIcon = $document.find('.fixed-action-icon')
  const $fixedActionsButton = $document.find('.fixed-actions-button')

  /** Tree View Menu **/
  var menuToggle = $(document).find('#menu-toggle')
  if (menuToggle.length) {
    menuToggle.on('click', function (e) {
      e.preventDefault()
      $(this).toggleClass('active')
      $sidebar.toggleClass('active-state')

      if ($fixedActionsButton.length) {
        if (
          !$fixedActionsButton ||
          $fixedActionsButton
            .find('#fixed-actions-button-checkbox')
            .prop('checked') === false
        ) {
          $mobilePageOverlay.toggleClass('active-state')
        }
      } else {
        $mobilePageOverlay.toggleClass('active-state')
      }
    })
  }

  if (window.innerWidth < 1441) {
    $sidebar.find('.has-siblings').removeClass('show-siblings')
  }

  $sidebar.find('.has-siblings').on('click', (e) => {
    const $currentTarget = $(e.currentTarget)

    if ($(e.target).closest('.siblings').length === 0) {
      $currentTarget.toggleClass('show-siblings')
    }
  })

  /** Mobile actions **/
  $('.mobile-aside-close').on('click', function () {
    $sidebar.removeClass('active-state')
    $('.main-box').removeClass('active-state')
    $('body').removeClass('menu-active')
  })

  /** Google map menu **/
  var googleMap = $(document).find('#google-map')
  if (googleMap.length) {
    // var mapMarkerIcon = require('../img/google-pin.png');

    // Show/hide map
    $('.top-widget-bar .google-map-button').on('click', function (e) {
      e.preventDefault()

      $(this).toggleClass('active')
      googleMap.toggleClass('active')
    })

    // Init google map
    const mapOptions = {
      zoom: 13,
    }

    if (typeof coords !== 'undefined' && Object.keys(coords).length !== 0) {
      mapOptions.center = new google.maps.LatLng(
        coords[coords.length - 1].latitude,
        coords[coords.length - 1].longitude,
      )
    } else {
      const lat =
        typeof defaultLat !== 'undefined'
          ? defaultLat
          : typeof teamLat !== 'undefined' && teamLat !== ''
            ? teamLat
            : '64.103329'
      const long =
        typeof defaultLong !== 'undefined'
          ? defaultLong
          : typeof teamLong !== 'undefined' && teamLong !== ''
            ? teamLong
            : '-21.8362107'

      mapOptions.center = new google.maps.LatLng(lat, long)
    }

    window.map = new google.maps.Map(
      document.getElementById('google-map'),
      mapOptions,
    )
  }

  /** Recent Tasks menu **/
  var recentTasksBlock = $(document).find('.recent-tasks .box-inner')
  if (recentTasksBlock.length) {
    recentTasksBlock.on('click', function (e) {
      e.preventDefault()

      $(this).toggleClass('active-state')
      $('.recent-tasks-dropbox').toggleClass('active-state')
    })
  }

  const datePickerField = $('.date-picker')
  if (datePickerField.length && jQuery().datepicker) {
    //Date picker
    datePickerField.datepicker({
      autoclose: true,
      todayHighlight: true,
      startDate: new Date(),
      firstDay: 1,
    })

    localeDatepicker()
  }

  $('.sidebar-menu .treeview:not(.active) li').each(function () {
    if ($(this).find('a').attr('href') === window.location.pathname) {
      $(this).closest('li.treeview').find('a:first-child').trigger('click')
    }
  })
  const startedTask = $('.started-task-box')
  if (startedTask.length) {
    $('main').addClass('add-height')
  }

  if (fixedActionIcon.length || $fixedActionsButton.length) {
    const $button = fixedActionIcon.length
      ? fixedActionIcon
      : $fixedActionsButton

    $(window).on('scroll', () => {
      const buttonReact = $button.get(0).getBoundingClientRect()
      const logoReact = $document
        .find('.footer-logo')
        .get(0)
        .getBoundingClientRect()

      $button.toggleClass(
        'over-logo',
        buttonReact.top <= logoReact.top + logoReact.height &&
          buttonReact.top + buttonReact.height > logoReact.top,
      )
    })

    $fixedActionsButton
      .find('#fixed-actions-button-checkbox')
      .on('change', (e) => {
        $mobilePageOverlay.toggleClass(
          'active-state for-fixed-button',
          $(e.currentTarget).prop('checked'),
        )
      })

    $fixedActionsButton.on('click', '.fixed-actions-button-options a', () => {
      $fixedActionsButton
        .find('#fixed-actions-button-checkbox')
        .prop('checked', false)
      $mobilePageOverlay.toggleClass('active-state for-fixed-button', false)
    })
  }

  $mobilePageOverlay.on('click', function () {
    $(this).removeClass('active-state for-fixed-button')
    $sidebar.removeClass('active-state')
    $('#menu-toggle').removeClass('active')
    $fixedActionsButton
      .find('#fixed-actions-button-checkbox')
      .prop('checked', false)
  })

  if (process.env.NODE_ENV === 'production') {
    new OnlineUsers()
  }

  new TaskStopPopup()

  // Global Search in header menu
  GlobalSearch()
})
