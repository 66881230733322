/** Custom Search with Splitted View **/
$.widget('custom.splittedAutocomplete', $.ui.autocomplete, {
  _create: function () {
    this._super()
    // Using a table makes the autocomplete forget how to menu.
    // With this we can skip the header row and navigate again via keyboard.
    this.widget().menu('option', 'items', '.ui-menu-item')
  },
  _renderMenu: function (ul, items) {
    const self = this
    const tasks = items.filter((item) => item.type === 'task')
    const projects = items.filter((item) => item.type === 'project')
    const customers = items.filter((item) => item.type === 'customer')

    const menuTemplate = $(`
      <div>
        ${
          tasks.length
            ? `<div class="tasks-block">
            <span class="tag">${Translator.trans('global.search.tags.tasks')}</span>
          </div>`
            : ''
        }
        ${
          projects.length
            ? `<div class="projects-block">
            <span class="tag">${Translator.trans('global.search.tags.projects')}</span>
          </div>`
            : ''
        }
        ${
          customers.length
            ? `<div class="customers-block">
            <span class="tag">${Translator.trans('global.search.tags.customers')}</span>
          </div>`
            : ''
        }
      </div>
    `)

    ul.html(menuTemplate)

    const $tasksBlock = ul.find('.tasks-block')
    const $projectsBlock = ul.find('.projects-block')
    const $customersBlock = ul.find('.customers-block')

    if ($tasksBlock.length) {
      let showMoreElement = null

      $.each(tasks, (index, item) => {
        if (index > 2 && !showMoreElement) {
          const $showMoreElement = $(
            `${
              tasks.length > 3
                ? `<label class="show-more-results-label" for="show-more-results-task-checkbox">${Translator.trans('global.search.show-more-results')} (${
                    tasks.length - 3
                  })</label><input id="show-more-results-task-checkbox" type="checkbox" class="hidden" />`
                : ''
            }`
          )
          $tasksBlock.append($showMoreElement)
          showMoreElement = true
        }
        self._renderItemData(ul, $tasksBlock, item)
      })
    }

    if ($projectsBlock.length) {
      let showMoreElement = null

      $.each(projects, (index, item) => {
        if (index > 2 && !showMoreElement) {
          const $showMoreElement = $(
            `${
              projects.length > 3
                ? `<label class="show-more-results-label" for="show-more-results-project-checkbox">${Translator.trans('global.search.show-more-results')} (${
                    projects.length - 3
                  })</label><input id="show-more-results-project-checkbox" type="checkbox" class="hidden" />`
                : ''
            }`
          )
          $projectsBlock.append($showMoreElement)
          showMoreElement = true
        }
        self._renderItemData(ul, $projectsBlock, item)
      })
    }

    if ($customersBlock.length) {
      let showMoreElement = null

      $.each(customers, (index, item) => {
        if (index > 2 && !showMoreElement) {
          const $showMoreElement = $(
            `${
              customers.length > 3
                ? `<label class="show-more-results-label" for="show-more-results-customer-checkbox">${Translator.trans('global.search.show-more-results')} (${
                    customers.length - 3
                  })</label><input id="show-more-results-customer-checkbox" type="checkbox" class="hidden" />`
                : ''
            }`
          )
          $customersBlock.append($showMoreElement)
          showMoreElement = true
        }
        self._renderItemData(ul, $customersBlock, item)
      })
    }
  },
  _renderItemData: function (ul, table, item) {
    return this._renderItem(table, item).data('ui-autocomplete-item', item)
  },
  _renderItem: function (table, item) {
    const self = this

    const newText = String(item.value).replace(
      new RegExp(this.term, 'gi'),
      "<span class='ui-state-highlight'>$&</span>"
    )

    return $('<div class="ui-menu-item" role="presentation">')
      .data('item.autocomplete', item)
      .append(self.options.getItem(item, newText))
      .appendTo(table)
  },
})
