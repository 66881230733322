import Pristine from 'pristinejs'
import Layout from '@core/Layout'
import { Spinner } from '@components/Spinner'
import { useHttp } from '@utils/useHttp'
import Task from '@routes/Tasks/Task'
import { Tasks } from '../../entities/tasks'

export default class TaskStopPopup extends Layout {
  constructor() {
    super()
    this.pristine = null
    this.options = {
      selectors: {
        target: '#popup-close-task-overlay',
        commentWrapper: '#popup-close-task-comment',
        form: '#popup-task-form',
      },
    }
    this.bindEvents()
  }

  bindEvents() {
    const handleClick = (e) => {
      e.preventDefault()

      const $this = $(e.currentTarget)

      if (!$this.hasClass('disabled')) {
        const taskId = $this.data('task-id')

        if (taskId && typeof Tasks !== 'undefined' && loadCommentFormOnTaskStop === 1) {
          this.loadCommentForm(taskId)
        } else {
          Task.stop(taskId)
        }
      }
    }

    this.$document.find('.task-stop-tracking').on('click', (e) => handleClick(e))
    this.$document.find('.task-stop').on('click', (e) => handleClick(e))
    this.$document.find('.details-task-stop').on('click', (e) => handleClick(e))
    this.$document.find('.widget-task-stop-tracking').on('click', (e) => handleClick(e))
  }

  bindPopupEvents(taskId) {
    this.$document
      .find(this.options.selectors.commentWrapper)
      .find('.pop-close-cross')
      .on('click', (e) => {
        e.preventDefault()
        this.removeCommentForm()
      })

    this.$document.find(`${this.options.selectors.form} button`).on('click', (e) => {
      e.preventDefault()
      const formData = this.$document.find(this.options.selectors.form)

      if (this.pristine && this.pristine.validate()) {
        this.disableSubmit()
        Task.stop(taskId, formData.find('.stop-task-comment').val())
      }
      return false
    })

    this.initializeValidation()
  }

  initializeValidation() {
    this.pristine = new Pristine(this.$document.find(this.options.selectors.form).get(0))
  }

  async loadCommentForm(taskId) {
    const { request } = useHttp()

    try {
      const response = await request(Routing.generate('app_ajax_comment_load_form', { taskId }))

      $('body').append(response.form)

      this.$document.find(this.options.selectors.target).removeClass('hidden')
      this.$document.find(this.options.selectors.commentWrapper).removeClass('hidden')

      if (typeof timerTimeStr !== 'undefined') {
        const TEdTimer = new TimeEdTimer({
          timeStr: timerTimeStr,
          setTotalTimeSelector: '.task-timer',
        })

        TEdTimer.run()
      }
      this.bindPopupEvents(taskId)
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  async disableSubmit() {
    const $submitButton = this.$document.find(`${this.options.selectors.form} button`)

    $submitButton.prop('disabled', true)
    Spinner($submitButton).prepend()
  }

  removeCommentForm() {
    this.$document.find(this.options.selectors.target).remove()
    this.$document.find(this.options.selectors.commentWrapper).remove()
  }
}
